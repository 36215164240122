.videoIframeContainer {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  margin-top: 6rem;
}
.videoIframeContainer iframe {
  display: block;
}

.videosThumbsContainer {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
}

.videosThumbsContainer .thumb {
  display: inline-block;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 260px;
  cursor: pointer;
  text-align: left;
}

.videosThumbsContainer .thumb img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .videoIframeContainer {
    margin-top: 64px;
  }
}

@media (max-width: 640px) {
  .videoIframeContainer {
    margin-top: 56px;
  }
}
